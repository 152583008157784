import axios from './index'

class order {

    static orderAction(params) {
        return axios.post(`NurseHomeControl.action`,params)
    }
}

export default order;
