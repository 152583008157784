import { render } from "./sorder.vue?vue&type=template&id=503eeb79&scoped=true"
import script from "./sorder.vue?vue&type=script&lang=js"
export * from "./sorder.vue?vue&type=script&lang=js"

import "./sorder.vue?vue&type=style&index=0&id=503eeb79&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-503eeb79"
/* hot reload */
if (module.hot) {
  script.__hmrId = "503eeb79"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('503eeb79', script)) {
    api.reload('503eeb79', script)
  }
  
  module.hot.accept("./sorder.vue?vue&type=template&id=503eeb79&scoped=true", () => {
    api.rerender('503eeb79', render)
  })

}

script.__file = "src/views/home/sorder/sorder.vue"

export default script